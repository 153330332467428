import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Injector, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {DocumentPipelineModule} from '@klippa/documentpipeline-api-angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {StoreModule} from '@ngrx/store';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateParser
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LocalStorageModule} from 'angular-2-local-storage';
import {SortablejsModule} from 'angular-sortablejs';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';

import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BaseComponent} from './base/base.component';
import {DirectivesModule} from './directives/directives.module';
import {CommonLayoutComponent} from './layouts/common-layout.component';
import * as fromUser from './modules/user/models/user.reducer';
import {
  AuthenticatedComponent
} from './pages/authenticated/authenticated.component';
import {
  AuthenticationComponent
} from './pages/unauthenticated/authentication.component';
import {LoginComponent} from './pages/unauthenticated/login/login.component';
import {reducers} from './reducers';
import {UiModule} from './shared/ui/ui.module';
import {CustomHandler, CustomParser} from './util/i18n';
import {JobUploadComponent} from './pages/authenticated/job-upload/job-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BaseComponent,
    AuthenticationComponent,
    CommonLayoutComponent,
    AuthenticatedComponent,
    JobUploadComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      parser: {provide: TranslateParser, useClass: CustomParser},
      missingTranslationHandler:
        {provide: MissingTranslationHandler, useClass: CustomHandler},
    }),
    LocalStorageModule.forRoot({prefix: 'jobupload', storageType: 'localStorage'}),
    DocumentPipelineModule.forRoot({basePath: environment.api_url}),
    AppRoutingModule,
    ReactiveFormsModule,
    SortablejsModule.forRoot({animation: 150}),
    StoreModule.forRoot(reducers),
    StoreModule.forFeature('user', fromUser.userReducer),
    FormsModule,
    UiModule,
    SnotifyModule,
    NgSelectModule,
    DirectivesModule,
    NgbModule,
    NgxFileDropModule,
  ],
  providers: [
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    BaseComponent.injector = this.injector;
  }
}
