import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DocumentPipelineService} from '@klippa/documentpipeline-api-angular';
import {APINotificationsService} from '../../../api/services/apinotifications.service';
import {Pipeline} from '@klippa/documentpipeline-api-js';

@Component({
  selector: 'app-pipeline-selector',
  templateUrl: './pipeline-selector.component.html',
})
export class PipelineSelectorComponent implements OnChanges {
  public pipelineList: {
    Value: string
    Key: string
  }[];

  public pipelines: Array<Pipeline>;

  public loading = true;

  public realValue: string;

  @Input()
  public company: string;

  @Input()
  get value() {
    return this.realValue;
  }

  set value(val) {
    this.realValue = val;
    this.valueChange.emit(this.realValue);
    let pipelineItem: Pipeline = null;
    if (this.pipelines && this.pipelines.length > 0) {
      const pipelinesFiltered = this.pipelines.filter((item) => `${item.ID}` === val);
      if (pipelinesFiltered.length > 0) {
        pipelineItem = pipelinesFiltered[0];
      }
    }
    this.setSelectedPipeline.emit(pipelineItem);
  }

  @Output()
  public valueChange = new EventEmitter<string | null>();

  @Output()
  public setSelectedPipeline = new EventEmitter<Pipeline | null>();

  constructor(private documentPipelineService: DocumentPipelineService, private notifications: APINotificationsService) { }

  loadPipelines() {
    this.loading = true;

    this.pipelines = [];
    this.pipelineList = [];
    this.documentPipelineService.pipelines.pipelinesListInput(null, null, null, null, this.company, 'JobUpload', 4, 'JobUpload').then(r => {
      if (r && r.data && r.data.Data && r.data.Data.Pipelines) {
        this.pipelines = r.data.Data.Pipelines;
        this.pipelineList = this.pipelines.map((val) => {
          return {
            Value: val.Name,
            Key: `${val.ID}`,
          };
        });
      }

      if (this.pipelineList.length === 1) {
        this.value = this.pipelineList[0].Key;
      }

      this.loading = false;
    }).catch(e => {
      this.notifications.handleAPIError(e);
      this.loading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.company) {
      this.realValue = null;
      this.valueChange.emit(this.realValue);
      this.loadPipelines();
    }
  }
}
