export function amountToString(amount: number): string {
  if (amount === undefined) {
    return '0.00';
  }
  if (isNaN(amount)) {
    return '0.00';
  }

  return (amount / 100).toFixed(2);
}

export function formatAmount(amount: string): number {
    if (!amount) {
      return 0;
    }

    if (amount.replace(/^\s+|\s+$/g, '') === '') {
      return 0;
    }

    if (!parseFloat(amount.replace(/,/g, '.'))) {
      return 0;
    }

    let iets = amount.replace(/,/g, '.');
    let anders = parseFloat(iets) * 100.00;
    let drie = Math.round(anders);
    let vijf = parseInt(drie.toString(), 10);

    if (isNaN(vijf)) {
      return 0;
    }

    return vijf;
}


