import {_} from '../util/i18n';

export class Strings {
  static capitalize(token: string): string {
    return token.charAt(0).toUpperCase() + token.slice(1);
  }

  static copy(document, text: string) {
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.left = '0';
      textArea.style.top = '0';
      textArea.style.opacity = '0';
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
  }
}

export class ModalMessages {
  static modalActionMessages = {
    'receipt': {
      'submit': {
        'success': [_('Submitted %count% of %totalCount% receipt.'), _('Submitted %count% of %totalCount% receipts.')],
        'failed': [_('Note: %count% receipt could not be submitted.'), _('Note: %count% receipts could not be submitted.')],
        'alert': [_('Submitted %count% receipt!'), _('Submitted %count% receipts!')],
        'confirmation': [_('Are you sure that you want to submit %count% expense?'), _('Are you sure that you want to submit %count% expenses?')],
        'note': [_('Note: It\'s possible that the receipt can\'t be submitted.'), _('Note: It\'s possible that not all receipts can be submitted.')],
        'alreadySubmitted': [_('Note: %count receipt was already submitted.'), _('Note: %count% receipts were already submitted.')],
      },
      'retract': {
        'success': [_('Retracted %count% of %totalCount% receipt.'), _('Retracted %count% of %totalCount% receipts.')],
        'failed': [_('Note: %count% receipt could not be retracted.'), _('Note: %count% receipts could not be retracted.')],
        'alert': [_('Retracted %count% receipt!'), _('Retracted %count% receipts!')],
        'confirmation': [_('Are you sure that you want to retract %count% expense?'), _('Are you sure that you want to retract %count% expenses?')],
        'note': [_('Note: It\'s possible that the receipt can\'t be retracted.'), _('Note: It\'s possible that not all receipts can be retracted.')],
        'notSubmitted': [_('Note: %count receipt was not submitted and could therefore not be retracted.'), _('Note: %count% receipts were not submitted and could therefore not be retracted.')],
      },
      'delete': {
        'success': [_('Deleted %count% of %totalCount% receipt.'), _('Deleted %count% of %totalCount% receipts.')],
        'failed': [_('Note: %count% receipt could not be deleted.'), _('Note: %count% receipts could not be deleted.')],
        'alert': [_('Deleted %count% receipt!'), _('Deleted %count% receipts!')],
        'confirmation': [_('Are you sure that you want to delete %count% receipt?'), _('Are you sure that you want to delete %count% receipts?')],
        'note': [_('Note: It\'s possible that the receipt can\'t be deleted.'), _('Note: It\'s possible that not all receipts can be deleted.')],
      },
      'copy': {
        'success': [_('Duplicated %count% of %totalCount% receipt.'), _('Duplicated %count% of %totalCount% receipts.')],
        'failed': [_('Note: %count% receipt could not be duplicated.'), _('Note: %count% receipts could not be duplicated.')],
        'alert': [_('Duplicated %count% receipt!'), _('Duplicated %count% receipts!')],
        'confirmation': [_('Are you sure that you want to duplicate %count% receipt?'), _('Are you sure that you want to duplicate %count% receipts?')],
      },
      'download': {
        'success': [_('Exporting the images of %totalCount% receipt.'), _('Exporting the images of %totalCount% receipts.')],
        'confirmation': [_('You are going to export the images of %count% receipt.'), _('You are going to export the images of %count% receipts.')],
      },
      'export': {
        'success': [_('Exporting %totalCount% receipt.'), _('Exporting %totalCount% receipts.')],
        'confirmation': [_('You are going to export %count% receipt.'), _('You are going to export %count% receipts.')],
      },
      'move': {
        'success': [_('Moved %count% receipt of %totalCount%.'), _('Moved %count% receipts of %totalCount%.')],
        'failed': [_('Note: %count% receipt could not be moved.'), _('Note: %count% receipts could not be moved.')],
        'alert': [_('Moved %count% receipt!'), _('Moved %count% receipts!')],
        'confirmation': [_('You are going to move %count% receipt.'), _('You are going to move %count% receipts.')],
      },
      'share': {
        'success': [_('Shared %count% receipt of %totalCount%.'), _('Shared %count% receipts of %totalCount%.')],
        'failed': [_('Note: %count% receipt could not be shared.'), _('Note: %count% receipts could not be shared.')],
        'alert': [_('Shared %count% receipt!'), _('Shared %count% receipts!')],
        'confirmation': [_('Are you sure you want to share %count% receipt.'), _('Are you sure you want to share %count% receipts.')],
      },
      'book': {
        'success': [_('Booked %count% of %totalCount% receipt.'), _('Booked %count% of %totalCount% receipts.')],
        'failed': [_('Note: %count% receipt could not be booked.'), _('Note: %count% receipts could not be booked.')],
        'alert': [_('Booked %count% receipt!'), _('Booked %count% receipts!')],
        'confirmation': [_('Are you sure that you want to book %count% expense?'), _('Are you sure that you want to book %count% expenses?')],
        'note': [_('Note: It\'s possible that the receipt can\'t be booked.'), _('Note: It\'s possible that not all receipts can be booked.')],
      },
    },
    'group': {
      'submit': {
        'success': [_('Submitted %count% of %totalCount% folder.'), _('Submitted %count% of %totalCount% folders.')],
        'failed': [_('Note: %count% folder could not be submitted.'), _('Note: %count% folders could not be submitted.')],
        'alert': [_('Submitted %count% folder!'), _('Submitted %count% folders!')],
        'confirmation': [_('Are you sure that you want to submit %count% expense?'), _('Are you sure that you want to submit %count% expenses?')],
        'note': [_('Note: It\'s possible that the folder can\'t be submitted.'), _('Note: It\'s possible that not all folders can be submitted.')],
        'alreadySubmitted': [_('Note: %count receipt was already submitted.'), _('Note: %count% receipts were already submitted.')],
      },
      'retract': {
        'success': [_('Retracted %count% of %totalCount% folder.'), _('Retracted %count% of %totalCount% folders.')],
        'failed': [_('Note: %count% folder could not be retracted.'), _('Note: %count% folders could not be retracted.')],
        'alert': [_('Retracted %count% folder!'), _('Retracted %count% folders!')],
        'confirmation': [_('Are you sure that you want to retract %count% expense?'), _('Are you sure that you want to retract %count% expenses?')],
        'note': [_('Note: It\'s possible that the folder can\'t be retracted.'), _('Note: It\'s possible that not all folders can be retracted.')],
        'notSubmitted': [_('Note: %count receipt was not submitted and could therefore not be retracted.'), _('Note: %count% receipts were not submitted and could therefore not be retracted.')],
      },
      'delete': {
        'success': [_('Deleted %count% of %totalCount% folder.'), _('Deleted %count% of %totalCount% folders.')],
        'failed': [_('Note: %count% folder could not be deleted.'), _('Note: %count% folders could not be deleted.')],
        'alert': [_('Deleted %count% folder!'), _('Deleted %count% folders!')],
        'confirmation': [_('Are you sure that you want to delete %count% folder?'), _('Are you sure that you want to delete %count% folders?')],
        'note': [_('Note: It\'s possible that the folder can\'t be deleted.'), _('Note: It\'s possible that not all folders can be deleted.')],
      },
      'download': {
        'success': [_('Exporting the images of %totalCount% folder.'), _('Exporting the images of %totalCount% folders.')],
        'confirmation': [_('You are going to export the images of %count% folder.'), _('You are going to export the images of %count% folders.')],
      },
      'export': {
        'success': [_('Exporting %totalCount% folder.'), _('Exporting %totalCount% folders.')],
        'confirmation': [_('You are going to export %count% folder.'), _('You are going to export %count% folders.')],
      },
      'move': {
        'success': [_('Moved %count% folder of %totalCount%.'), _('Moved %count% folders of %totalCount%.')],
        'failed': [_('Note: %count% folder could not be moved.'), _('Note: %count% folders could not be moved.')],
        'alert': [_('Moved %count% folder!'), _('Moved %count% folders!')],
        'confirmation': [_('You are going to move %count% folder.'), _('You are going to move %count% folders.')],
      },
      'share': {
        'success': [_('Shared %count% folder of %totalCount%.'), _('Shared %count% folders of %totalCount%.')],
        'failed': [_('Note: %count% folder could not be shared.'), _('Note: %count% folders could not be shared.')],
        'alert': [_('Shared %count% folder!'), _('Shared %count% folders!')],
        'confirmation': [_('Are you sure you want to share %count% folder.'), _('Are you sure you want to share %count% folders.')],
      },
      'change-status': {
        'success': [_('Updated %count% expense of %totalCount%.'), _('Updated %count% expenses of %totalCount%.')],
        'failed': [_('Note: %count% expense could not be updated.'), _('Note: %count% expenses could not be updated.')],
        'alert': [_('Updated %count% expense!'), _('Updated %count% expenses!')],
        'confirmation': [_('You are going to update %count% expense.'), _('You are going to update %count% expenses.')],
      },
    },
    'undefined': {
      'undefined': {
        'success': [_('Processed %count% of %totalCount% item.'), _('Processed %count% of %totalCount% items.')],
        'failed': [_('Note: %count% item could not be processed.'), _('Note: %count% items could not be processed.')],
        'alert': [_('%count% item processed!'), _('%count% items processed!')],
        'confirmation': [_('Are you sure you want to process %count% item?'), _('Are you sure you want to process %count% items?')],
        'note': [_('Note: It\'s possible that the item can\'t be processed.'), _('Note: It\'s possible that not all items can be processed.')],
      }
    },
    'tag': {
      'submit': {
        'success': [_('Submitted %count% of %totalCount% tag.'), _('Submitted %count% of %totalCount% tags.')],
        'failed': [_('Note: %count% tag could not be submitted.'), _('Note: %count% tags could not be submitted.')],
        'alert': [_('Submitted %count% tag!'), _('Submitted %count% tags!')],
        'confirmation': [_('Are you sure that you want to submit %count% tag?'), _('Are you sure that you want to submit %count% tags?')],
        'alreadySubmitted': [_('Note: %count receipt was already submitted.'), _('Note: %count% receipts were already submitted.')],
      },
      'retract': {
        'success': [_('Retracted %count% of %totalCount% tag.'), _('Retracted %count% of %totalCount% tags.')],
        'failed': [_('Note: %count% tag could not be retracted.'), _('Note: %count% tags could not be retracted.')],
        'alert': [_('Retracted %count% tag!'), _('Retracted %count% tags!')],
        'confirmation': [_('Are you sure that you want to retract %count% tag?'), _('Are you sure that you want to retract %count% tags?')],
        'notSubmitted': [_('Note: %count receipt was not submitted and could therefore not be retracted.'), _('Note: %count% receipts were not submitted and could therefore not be retracted.')],
      },
      'delete': {
        'success': [_('Deleted %count% of %totalCount% tag.'), _('Deleted %count% of %totalCount% tags.')],
        'failed': [_('Note: %count% tag could not be deleted.'), _('Note: %count% tags could not be deleted.')],
        'alert': [_('Deleted %count% tag!'), _('Deleted %count% tags!')],
        'confirmation': [_('Are you sure that you want to delete %count% tag?'), _('Are you sure that you want to delete %count% tags?')],
        'note': [_('Note: It\'s possible that the tag can\'t be deleted.'), _('Note: It\'s possible that not all tags can be deleted.')],
      },
      'change-status': {
        'success': [_('Updated %count% tag.'), _('Updated %count% tags of %totalCount% tags.')],
        'failed': [_('Note: %count% tag could not be updated due to status or permissions.'), _('Note: %count% tags could not be updated due to status or permissions.')],
      }
    }
  };

  static usePlural(count: number): number {
    if (count === 1) {
      return 0;
    }
    return 1;
  }

  static getCurrentCountSentence(type: string, action: string, success: string, count: number, totalCount?: number): string {
    if (this.modalActionMessages[type] === undefined || this.modalActionMessages[type][action] === undefined || this.modalActionMessages[type][action][success] === undefined || this.modalActionMessages[type][action][success][this.usePlural(count)] === undefined) {
      return this.modalActionMessages['undefined']['undefined'][success][this.usePlural(count)];
    }
    return this.modalActionMessages[type][action][success][this.usePlural(count)];
  }

  static getCurrentCountSentenceTranslated(type: string, action: string, success: string, count: number, totalCount?: number) {
    const message = this.getCurrentCountSentence(type, action, success, count, totalCount);
    const params = {'count': count, 'totalCount': totalCount};
    return {
      'message' : message,
      'params' : params
    };
  }

  static getUserNamedType(str: string) {
    if (str === 'group') {
      return 'folder';
    }
    return str;
  }
}
