import {Component, OnDestroy, OnInit} from '@angular/core';
import {Logout} from '../../../modules/user/models/user.actions';
import {AuthenticatedComponent} from '../authenticated.component';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import { Pipeline, PipelineInput, CreatedJob } from '@klippa/documentpipeline-api-js';
import {_} from '../../../util/i18n';

@Component({
  selector: 'app-job-upload',
  templateUrl: './job-upload.component.html',
  styleUrls: ['./job-upload.component.scss']
})
export class JobUploadComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
  public app: any;
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any;
  public sidenavSelected: any;

  public selectedCompanyID: string = null;
  public selectedPipelineID: string = null;
  public selectedPipeline: Pipeline = null;
  public selectedInput: PipelineInput = null;
  public selectedInputJobPerFile: boolean = null;
  public files: NgxFileDropEntry[] = [];
  public resultingJobs: CreatedJob[] = [];
  public loading = false;

  constructor() {
    super();

    this.app = {
      layout: {
        isMenuOpened: true,
        isMenuCollapsed: false,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };

    this.headerThemes = [
      'header-default', 'header-primary', 'header-info', 'header-success',
      'header-danger', 'header-dark'
    ];
    this.changeHeader = changeHeader;

    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }

    this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    this.changeSidenav = changeSidenav;

    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.resultingJobs = [];
    this.files = files;
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
  }

  public async upload() {
    this.loading = true;
    const formData = new FormData();
    for (const droppedFile of this.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        await new Promise((resolve, reject) => {
          fileEntry.file((file: File) => {
            formData.append('input_files', file, droppedFile.relativePath);
            resolve();
          });
        });
      }
    }

    if (this.selectedInput == null) {
      this.notificationService.error(this.translate.instant(_('Could not find input to upload files to.')));
      this.loading = false;
      return;
    }

    if (!formData.has('input_files')) {
      this.notificationService.error(this.translate.instant(_('No files were selected.')));
      this.loading = false;
      return;
    }

    await this.documentPipelineService.axiosInstance.request({
      method: 'POST',
      url: this.documentPipelineService.basePath + `/Input/JobUpload/${this.selectedInput.ID}`,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' }
    })
    .then((res) => {
      this.files = [];
      this.resultingJobs = [];

      if (res && res.data && res.data.Data && res.data.Data.CreatedJobs && res.data.Data.CreatedJobs.length > 0) {
        this.resultingJobs = res.data.Data.CreatedJobs.map((item) => {
          const newJob: CreatedJob = {
            Description: item.Description,
            JobID: item.JobID,
            PipelineID: item.Description,
          };
          return newJob;
        });
      }

      this.loading = false;
    }).catch((e) => {
      this.notifications.handleAPIError(e);
      this.loading = false;
    });
  }

  setSelectedPipeline($event) {
    this.reset();
    this.selectedPipeline = $event;
    this.selectedInput = null;
    this.selectedInputJobPerFile = null;
    if ($event) {
      if (this.selectedPipeline && this.selectedPipeline.Input && this.selectedPipeline.Input.length > 0) {
        const filteredInputs = this.selectedPipeline.Input.filter((item) => item.Type === 'JobUpload');
        if (filteredInputs.length > 0) {
          this.selectedInput = filteredInputs[0];

          if (this.selectedInput.Configuration && this.selectedInput.Configuration.length > 0) {
            this.selectedInputJobPerFile = false;
            this.selectedInput.Configuration.forEach(config => {
              if (config.Key === 'JobPerFile' && config.Value === '1') {
                this.selectedInputJobPerFile = true;
              }
            });
          }
        }
      }
    }
  }

  reset() {
    this.files = [];
    this.resultingJobs = [];
  }

  logoutUser() {
    this.router.navigate(['/login']).then(() => {
      this.documentPipelineService.tokenStorage.setKey(null);
      this.documentPipelineService.tokenStorage.setSecret(null);
      this.documentPipelineService.tokenStorage.setRefreshToken(null);
      this.store.dispatch(new Logout());
      this.notificationService.success('Log out succesful.');
    });
  }

  get currentYear() {
    return (new Date()).getFullYear();
  }
}
