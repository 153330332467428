import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../helpers/pipes/pipes.module';
import { LoaderComponent } from './loader/loader.component';
import {CompanySelectorComponent} from './company-selector/company-selector.component';
import {PipelineSelectorComponent} from './pipeline-selector/pipeline-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    PipesModule,
    MomentModule
  ],
  declarations: [
    LoaderComponent,
    CompanySelectorComponent,
    PipelineSelectorComponent,
  ],
  exports: [
    LoaderComponent,
    CompanySelectorComponent,
    PipelineSelectorComponent,
  ],
  entryComponents: [
  ]
})
export class UiModule {
}
