import {Inject, Injectable} from '@angular/core';
import {SnotifyToastConfig} from 'ng-snotify';
import {NotificationService} from '../../services/notification.service';
import {DOCUMENT} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Errors} from '../errors.enum';
import {_} from '../../util/i18n';
import {TranslateService} from '@ngx-translate/core';
import {Logout} from '../../modules/user/models/user.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers';
import {DocumentPipelineService} from '@klippa/documentpipeline-api-angular';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class APINotificationsService {
  static errors = {
    0: _('Something went wrong, please try again.'),
    100001: _('Missing X-Auth-Key header'),
    100002: _('Missing X-Auth-Timestamp header'),
    100003: _('Missing X-Auth-Nonce header'),
    100004: _('Missing X-Auth-Signature header'),
    100005: _('Request expired: timestamp is too old or too new, try syncing your time with NTP.'),
    100006: _('Nonce is too long.'),
    100007: _('Anvalid nonce, should be [a-zA-Z0-9]'),
    100008: _('Nonce has already been used'),
    100009: _('Unknown key'),
    100010: _('Invalid signature'),
    100011: _('Signature mismatch'),
    100012: _('Could not load user'),
    100013: _('User is disabled'),
    100014: _('Token Expired, use the refresh token to renew your token'),
    100016: _('Insufficient Permissions'),
    400001: _('Login failed'),
    400002: _('User is disabled'),
    300000: _('No companies found. You do not have access to any companies that can use this portal. You have been logged out.'),
  };

  constructor(private notificationService: NotificationService, @Inject(DOCUMENT) private document, public translate: TranslateService, public store: Store<AppState>, public documentPipelineService: DocumentPipelineService, public router: Router) {
  }

  getValidationErrors(APIResult: any, html: boolean = false): string {
    const errors = APIResult.response.data.Errors;
    const messages = [];
    errors.forEach(error => {
      error.fieldNames.forEach(fieldName => {
        if (error.classification === 'RequiredError') {
          messages.push('Field ' + fieldName + ' is required.');
        } else {
          messages.push('Field ' + fieldName + ': ' + error.message);
        }
      });
    });

    return messages.join('\n');
  }

  handleAPIError(APIResult: any) {
    if (typeof APIResult.response.data !== 'object') {
      this.notificationService.error(this.translate.instant(_('Something went wrong, please try again.')),
        null);
      return;
    }

    if (APIResult.response.data.ErrorCode === Errors.InputValidationError) {
      return this.notificationService.error(this.translate.instant(_('Form validation error')) + ': ' + this.getValidationErrors(APIResult),
        null);
    }

    // When a code is mapped to null, ignore this error.
    if (APINotificationsService.errors[APIResult.response.data.ErrorCode] === null) {
      return true;
    } else if (APINotificationsService.errors[APIResult.response.data.ErrorCode]) {
      if (APIResult.response.data.ErrorCode === 300000 && APIResult.response.status === 404) {
        this.logOut();
        return this.notificationService.info(this.translate.instant(APINotificationsService.errors[APIResult.response.data.ErrorCode]), null, {
          timeout: 0,
        });
      }
      return this.notificationService.error(this.translate.instant(APINotificationsService.errors[APIResult.response.data.ErrorCode]),
        null);
    } else {
      return this.notificationService.error('An API error occurred: ' + APIResult.response.data.ErrorMessage, null);
    }
  }

  logOut() {
    this.router.navigate(['/login']).then(() => {
      this.documentPipelineService.tokenStorage.setKey(null);
      this.documentPipelineService.tokenStorage.setSecret(null);
      this.documentPipelineService.tokenStorage.setRefreshToken(null);
      this.store.dispatch(new Logout());
    });
  }
}
