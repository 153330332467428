import {amountToStringPipe} from './numbers/amounttostring.modules';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CapitalizePipe} from './strings/capitalize.pipe';
import {ReceiptAmountPipe} from './numbers/receipt-amount.pipe';
import {RoundnumberPipe} from './numbers/roundnumber.pipe';
import {DistanceAmountPipe} from './numbers/distance-amount.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CapitalizePipe,
    amountToStringPipe,
    ReceiptAmountPipe,
    RoundnumberPipe,
    DistanceAmountPipe
  ],
  exports: [
    CapitalizePipe,
    amountToStringPipe,
    ReceiptAmountPipe,
    RoundnumberPipe,
    DistanceAmountPipe
  ],
})
export class PipesModule {
}
