import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentPipelineService} from '@klippa/documentpipeline-api-angular';
import {APINotificationsService} from '../../../api/services/apinotifications.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
})
export class CompanySelectorComponent implements OnInit {
  public companyList: {
    Value: string
    Key: string
  }[];

  public loading = true;

  public realValue: string;

  @Input()
  get value() {
    return this.realValue;
  }
  set value(val) {
    this.realValue = val;
    this.valueChange.emit(this.realValue);
  }
  @Output()
  public valueChange = new EventEmitter<string | null>();

  constructor(private documentPipelineService: DocumentPipelineService, private notifications: APINotificationsService) { }

  ngOnInit() {
    this.loadCompanies();
  }

  loadCompanies() {
    this.loading = true;

    this.documentPipelineService.companies.companiesListInput(null, null, null, null, null, 'JobUpload', 4, 'JobUpload').then(r => {
      this.companyList = [];
      if (r && r.data && r.data.Data && r.data.Data.Companies) {
        this.companyList = r.data.Data.Companies.map((val) => {
          return {
            Value: val.Name,
            Key: `${val.ID}`,
          };
        });
      }

      if (this.companyList.length === 1) {
        this.value = this.companyList[0].Key;
      }

      this.loading = false;
    }).catch(e => {
      this.notifications.handleAPIError(e);
      this.loading = false;
    });
  }
}
