import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/unauthenticated/login/login.component';
import {CurrentUserResolve} from './modules/user/models/user.resolver.currentUser';
import {JobUploadComponent} from './pages/authenticated/job-upload/job-upload.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'job-upload',
    component: JobUploadComponent,
    resolve: {
      currentUser: CurrentUserResolve,
    }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CurrentUserResolve],
})
export class AppRoutingModule { }
