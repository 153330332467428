import {Pipe, PipeTransform} from '@angular/core';
import {amountToString} from '../../numbers';

@Pipe({
  name: 'amount'
})
export class ReceiptAmountPipe implements PipeTransform  {
  transform(value: number): string {
    return amountToString(value);
  }
}
