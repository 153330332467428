import {Pipe, PipeTransform} from '@angular/core';
import {Strings} from '../../strings';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    return Strings.capitalize(value);
  }
}
