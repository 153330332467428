import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {User} from '@klippa/documentpipeline-api-js';

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html'
})
export class AuthenticatedComponent extends BaseComponent implements OnInit {
  public user: User;

  constructor() {
    super();
  }

  ngOnInit() {
    const userStoreSubscription = this.store.select('user').subscribe(val => {
      this.user = val.currentUser;
    });

    this.destroyCallbacks.push(() => {
      userStoreSubscription.unsubscribe();
    });
  }
}
