import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers/index';
import {Login, Logout, Update, UpdateKey} from './user.actions';
import {NotificationService} from '../../../services/notification.service';
import 'rxjs/add/operator/take';
import {TranslateService} from '@ngx-translate/core';
import {_} from '../../../util/i18n';
import {SnotifyService} from 'ng-snotify';
import {DocumentPipelineService} from '@klippa/documentpipeline-api-angular';
import {User} from '@klippa/documentpipeline-api-js';

@Injectable()
export class CurrentUserResolve implements Resolve<User>, OnDestroy {

  constructor(private router: Router, private store: Store<AppState>, private documentPipelineService: DocumentPipelineService, private notificationService: NotificationService, private translate: TranslateService, private snotifyService: SnotifyService) {
  }

  ngOnDestroy() {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise<User>((res, rej) => {
      return this.store.select('user').take(1).subscribe(val => {
        // User already loaded, redirect.
        if (val.currentUser != null) {
          return res(val.currentUser);
        }

        if (this.documentPipelineService.tokenStorage.getKey() !== null && this.documentPipelineService.tokenStorage.getKey() !== '') {
          return this.documentPipelineService.user.userMe().then((r) => {
            this.store.dispatch(new Login({user: r.data.Data}));
            this.store.dispatch(new UpdateKey({key: this.documentPipelineService.tokenStorage.getKey(), secret: this.documentPipelineService.tokenStorage.getSecret()}));
            return res(r.data.Data);
          }).catch((r) => {
            // Error handling is handled by UserService.

            // Trigger logout for the user. Key error.
            this.store.dispatch(new Logout());
            this.notificationService.info(this.translate.instant(_('An error occurred while fetching your profile. Please login again.')));

            // @todo: possibly add redirect param to login.
            this.router.navigate(['/login']);
            return res(null);
          });
        } else {
          // Not logged in at all. Redirect.
          this.notificationService.info(this.translate.instant(_('Please login to access this page.')));

          // @todo: possibly add redirect param to login.
          this.router.navigate(['/login']);
          return res(null);
        }
      });
    });
  }
}
