import {Pipe, PipeTransform} from '@angular/core';
import {amountToString} from '../../numbers';

@Pipe({
  name: 'distance'
})
export class DistanceAmountPipe implements PipeTransform  {
  transform(value: number): string {
    if (!value) {
      const newVal = 0.0;
      return newVal.toFixed(2);
    }
    return value.toFixed(2);
  }
}
